import Jed from 'jed';

export default (translations) => {
  const i18n = new Jed(translations);

  /* eslint-disable */
  window.__ = function () {
    return i18n.gettext.apply(i18n, arguments);
  };

  window.n__ = function () {
    // eslint-disable-next-line prefer-spread
    return i18n.ngettext.apply(i18n, arguments);
  };

  window.s__ = function (key) {
    return window.__(key).split('|').pop();
  };

  window.ns__ = function () {
    return window.n__.apply(this, arguments).split('|').pop();
  };

  window.N__ = function () {};

  window.Nn__ = function () {};

  // Allow chaining interpolation with Gettext translated strings
  //
  //  __('Hello %{name}').i__({name: 'World'})
  //
  String.prototype.i__ = function (object) {
    let interpolated = `${this}`;

    for (const variable in object) {
      const value = object[variable];

      interpolated = interpolated.replaceAll(`%{${variable}}`, value);
    }

    return interpolated;
  };
  /* eslint-enable */
};
